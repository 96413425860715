<template>
  <div class="mine">
    <!-- 操作栏 -->
    <div class="mine-setting">
      <van-cell-group>
		 <van-icon name="shop-o" style="position: absolute;z-index: 99;left: 15px;top: 14px;"/>
        <van-field name="is_appointment" label="营业开关">
          <template #input class="business_location">
            <van-switch v-model="is_business" size="20" @click="businessChange()"/>
          </template>
        </van-field>
        <van-cell icon="clock-o" title="营业时间" @click="modifyBusiness" is-link/>
      </van-cell-group>
    </div>
    <van-popup v-model:show="business" style="border-radius: 10px;">
      <van-form @submit="businessSubmit" class="pass-form">
        <van-field
            label="开始时间"
            label-width="5rem"
            size="large"
            clearable
            v-model="business_start_time"
            name="validator"
            placeholder="请输入如：08:00"
            :rules="[{ required:true, message: '请输入如：08:00' }]"
        />
        <van-field
            label="结束时间"
            size="large"
            label-width="5rem"
            clearable
            v-model="business_end_time"
            name="pattern"
            placeholder="请输入如：18:00"
            :rules="[{ required:true, message: '请输入如：18:00' }]"
        />
        <div style="margin-top:2rem;">
          <van-button round block type="primary" native-type="submit">提交修改</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>
<script>
import { getBusinessInfo, modifyBusiness ,modifyIsBusiness } from '@/api/user'
import { toUrl } from '@/utils/tools'
import {Toast,Dialog} from "vant";

export default {
  data() {
    return {
      is_business: false,
      business: false,
      info: {},
      redirect: window.location.href,
      business_start_time: '',
      business_end_time: ''
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      getBusinessInfo().then((res) => {
		  console.log(res)
        this.info = res.data
        this.business_start_time =  res.data.business_start_time
        this.business_end_time =  res.data.business_end_time
        this.is_business = res.data.is_business ? true : false
      })
    },
    modifyBusiness() {
      this.business = !this.pass
    },
    businessSubmit() {
      modifyBusiness({'business_start_time':this.business_start_time,'business_end_time':this.business_end_time}).then((res)=>{
        Toast({
          message:res.msg
        });
        this.$router.go(-1)
      })
    },
    businessChange(){
      // console.log(this.is_business);
      modifyIsBusiness({'is_business':this.is_business}).then((res)=>{
        Toast({
          message:res.msg
        });
        // this.$router.go(-1)
      })
    }
  }
}
</script>
<style lang="less">
.mine {
  min-height: calc(100vh);
  .mine-card {
    background: #ffffff;
    padding: 30px 15px;
  }
  .mine-setting {
    margin: 10px 10px;
    border-radius: 10px;
    overflow: hidden;
  }
  .pass-form {
    width: 18rem;
    padding: 2rem 1rem;
  }
  //.van-cell__value{
  //  margin-left: 54%;
  //}
  .van-field__label{
    margin-left: 6%;
  }
  .van-cell-group{
    margin-top: 3%;
  }
}
</style>
