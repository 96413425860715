import request from '@/utils/request'

// 登录方法
export function info() {
    return request({
        url: '/info',
        method: 'get'
    })
}

// 登录方法
export function addressLst() {
    return request({
        url: '/address/lst',
        method: 'get'
    })
}

export function areaAdd(data) {
    return request({
        url: '/address/add',
        method: 'post',
        data
    })
}

export function areaEdit(data) {
    return request({
        url: '/address/edit',
        method: 'post',
        data
    })
}

export function areaDel(data) {
    return request({
        url: '/address/del',
        method: 'delete',
        data
    })
}

export function areaInfo(params) {
    return request({
        url: '/address/info',
        method: 'get',
        params
    })
}

export function modifyPassword(data) {
    return request({
        url: '/password/reset',
        method: 'post',
        data: data
    })
}
//门店营业相关信息
export function getBusinessInfo(data) {
    return request({
        url: '/store/storeinfo',
        method: 'get'
    })
}
//门店营业时间设置
export function modifyBusiness(data) {
    return request({
        url: '/store/businessedit',
        method: 'post',
        data: data
    })
}
//门店营业开关
export function modifyIsBusiness(data){
    return request({
        url: '/store/isbusiness',
        method: 'post',
        data: data
    })
}
// 门店预约时间段
export function appointTime(data){
    return request({
        url: 'storeappointment/lst',
        method: 'get',
        data: data
    })
}
// 添加时间段
export function addTime(data){
    return request({
        url: 'storeappointment/add',
        method: 'post',
        data: data
    })
}
// 删除时间段
export function delTime(data){
    return request({
        url: 'storeappointment/del',
        method: 'post',
        data: data
    })
}
// 时间段详情
export function detailTime(data){
    return request({
        url: 'storeappointment/detail',
        method: 'post',
        data: data
    })
}
// 编辑时间段
export function editTime(data){
    return request({
        url: 'storeappointment/edit',
        method: 'post',
        data: data
    })
}

// 编辑门店配置
export function editconfigs(params){
    return request({
        url: 'store/editconfigs',
        method: 'POST',
        params: params
    })
}

//门店配置详情
export function storepresentconfigs(params){
    return request({
        url: 'store/presentconfigs',
        method: 'get',
        params: params
    })
}
// 上传文件
export function uploadFile(data){
    return request({
        url: 'upload/image/file',
        method: 'PUT',
        data: data
    })
}

// 修改消息
export function amendMes(data){
    return request({
        url: 'index/edit',
        method: 'POST',
        data: data
    })
}

// 编辑门店图轮播图
export function editImage(data){
    return request({
        url: 'store/editImage',
        method: 'post',
        data: data
    })
}

// 门店图轮播图详情
export function getImage(data){
    return request({
        url: 'store/getImage',
        method: 'GET',
        data: data
    })
}
